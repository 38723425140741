import Question from '@/views/questionnaire/components/Question';
import wx from '../../../common/wx';
import {
	getQuestionnaire,
	submitQuestion
} from "./api";
export default {
	name: "answer",
	components: {
		Question
	},
	data() {
		return {
			breadcrumbData: [{
					path: "/index",
					name: "index",
					title: "首页"
				},
				{
					path: "/questionnaire/toBeAswered",
					name: "toBeAswered",
					title: "待答问卷"
				},
				{
					path: "/aswerer",
					name: "aswerer",
					title: "问卷作答"
				},
			],
			questionId: '',
			title: '',
			explain: '', // 描述
			questions: [],
			time: '',
			loading: false,
			isPhone: false,
			source: '', //source=当前平台  取值：XCX     APP     WEBNEW
			account: '', // account=当前商家登录的账号
			taskId: '',
			initiator: '', // 发起人
			submitted: false, //已提交
			cancled: false, //已拒绝
			subMsg: '请关闭当前页面以返回APP/小程序',
			submitRule: 0, //为1或2时只能答题一次
			firstShow: false,
			firstMsg: '',
			subShow: false,
			cancleShow: false,
			cancletMsg: '',
            noAuthority: false,//无权限
            noAuthorityMsg: '', //无权限提示
            xcxFromCode: true, //小程序从哪进来 true从二维码，false列表
			relationId: ''
		};
	},
	watch: {},
	computed: {

	},
	created() {
    },
	mounted() {
        let questionId = this.$route.query.questionId;
        if(!questionId) {
            questionId = this.getQueryValue('questionId')
        }
        if(!questionId) {
            questionId = this.getQuestionId();
        }
		this.questionId = questionId
		this.source = this.getQueryValue('source')
		this.taskId = this.getQueryValue('taskId')
		this.initiator = this.getQueryValue('initiator')
		let xcxFromCode = this.getQueryValue('xcxFromCode')
		if(xcxFromCode == 1) {
			this.xcxFromCode = false
		}
		let account = this.getQueryValue('account')
        if(!account) {
            if(this.$store.state.user.userInfo && this.$store.state.user.userInfo.account.account) {
                account = this.$store.state.user.userInfo.account.account;
            } else {
                account = '';
                let url = '/questionnaire/answer?source=WEBNEW&questionId=' + this.questionId + '&taskId=' + this.taskId + '&initiator=' + this.initiator
                this.$router.push("/login?redirect=" + url);
                return;
            }
        }
        this.account = account;
		this.getQuestionnaire();
		this.isPhone = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
		// this.$confirm({
		// 	title: '提示',
		// 	content: '本次问卷作答需一次完成，中途离开将不会保存，请预留好您的时间。完成本次问卷预计需要3分钟，是否确认开始答题？',
		// 	okText: '确认',
		// 	okType: 'danger',
		// 	cancelText: '取消',
		// 	onCancel: () => {
		// 		if (this.source == 'WEBNEW') {
		// 			this.$router.go(-1);
		// 		}
		// 		if (this.source == 'XCX') {
		// 			this.cancled = true;
		// 			this.subMsg = '请关闭当前页面以返回小程序';
		// 			return;
		// 		}
		// 		if (this.source == 'APP') {
		// 			this.cancled = true;
		// 			this.subMsg = '请关闭当前页面以返回APP';
		// 			return;
		// 		}
		// 	}
		// });
		
		// const msg = this.$configTotal({
		// 	route: this.$route.fullPath,
		// 	id: '14263301170'
		// });
		// this.firstMsg = msg.msgDesc;
	},
	methods: {
		cancleAnswer() {
			if (this.source == 'WEBNEW') {
				// this.$router.go(-1);
                this.$router.push('/questionnaire')
			}
			if (this.source == 'XCX') {
				this.cancled = true;
				this.subMsg = '请关闭当前页面以返回小程序';
				this.xcxJump();
				return;
			}
			if (this.source == 'APP') {
				this.cancled = true;
				this.subMsg = '请关闭当前页面以返回APP';
				return;
			}
		},
		getQueryValue(queryName) {
			let reg = `(^|&)${queryName}=([^&]*)(&|$)`
			let r = window.location.hash.substr(13).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
		},
        getQuestionId() {
            let url = window.location.hash;
            url = url.replace('amp%3B','')
            url = url.replace('amp;','')
            let queryName = 'questionId';
            let reg = `(^|&)${queryName}=([^&]*)(&|$)`
			let r = url.substr(13).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
        },
        home() {
            if(this.isPhone) {
                if(this.source == 'XCX') {
                    this.xcxJump()
                }
                if(this.source == 'APP') {
                    this.appJump()
                }
            } else {
                this.$router.push({path: "answered"})
            }
        },
		getQuestionnaire() {
			let data = {
				questionId: this.questionId,
				account: this.account
			}
			this.loading = true;
			getQuestionnaire(data).then(res => {
				if(res.code == 400) {
					this.noAuthority = true;
                    this.noAuthorityMsg = '系统异常'
					return
				}
                if(res.data.visible) {
                    this.firstShow = true;
                    this.title = res.data.title;
                    this.explain = res.data.explain;
                    this.submitRule = res.data.submitRule;
                    let questions = res.data.questionSubDtoList;
                    questions.sort((a, b) => {
                        return a.orderBy - b.orderBy
                    })
                    let no = 0;
                    for (let item of questions) {
                        item.show = true;
                        no++
                        if(item.isDel == 1) {
                            item.show = false;
                            no--;
                        }
                        item.no = no;
                        item.val = ''
                        let typeId = parseInt(item.typeId);
                        switch (typeId) {
                            case 701:
                                item.type = 'radio'
                                break;
                            case 702:
                                item.type = 'checkbox'
                                break;
                            case 703:
                                item.type = 'select'
                                break;
                            case 704:
                                item.type = 'input'
                                break;
                            case 705:
                                item.type = 'textarea'
                                break;
                            case 707:
                                item.type = 'slider';
                                item.min = item.subItemDtoList[0].min
                                item.minText = item.subItemDtoList[0].minExplain
                                item.max = item.subItemDtoList[0].max
                                item.maxText = item.subItemDtoList[0].maxExplain
                                item.options = [];
                                break;
                            case 706:
                                item.type = 'rate'
                                break;
                        }
                        item.isMust = item.isRequire == 1;
                        item.isMap = item.isMappingSelect == 1;
                        item.direction = item.explain;
                        item.rateStyle = item.style;
                        item.answerContent = null;
                        if (item.typeId != '707') {
                            item.min = item.minSelect;
                            item.max = item.maxSelect;
                            let options = item.subItemDtoList;
                            for (let option of options) {
                                option.label = option.optionExplain;
                                option.img = option.imageUrl || '';
                                option.isFill = option.isBlank == 1;
                                option.isMust = option.isRequire == 1;
                                option.score = option.fraction || 0;
                                option.checkedFlag = false,
                                    option.answerBlank = ''
                            }
                            options.sort((a, b) => {
                                return a.orderBy - b.orderBy
                            })
                            item.options = options;
                        }
                    }

                    this.questions = questions;
                    this.time = new Date().getTime();
                } else if(res.data.errorStatus == 2){ //没权限
                    this.noAuthority = true;
                    this.noAuthorityMsg = res.data.errorMsg
                } else if(res.data.errorStatus == 1){ //已作答
                    // app跳转问卷详情
                    if(this.source == 'APP') {
                        this.relationId = res.data.relationId
                    }
                    // 小程序跳转列表·已答列表
                    this.noAuthority = true;
                    this.noAuthorityMsg = res.data.errorMsg
                }
                this.loading = false;
			})
		},
        appJump() {
			if(!this.relationId) return;
            this.$router.push({
                path: '/questionnaire/checkAnswer',
                query: {
                    questionId: this.relationId
                }
            });
        },
        xcxJump() {
			wx.miniProgram.postMessage({ data: {foo: 'bar'} })
			// alert('xcxJump')
			if (this.xcxFromCode) {
				wx.miniProgram.navigateTo({
					url: '/pages/me/survey/index', //跳转回小程序的页面,传参id, 小程序onLoad函数接收参数即可
					success: function() {
						// alert('navigateTo')
					},
					fail: function() {
						// alert('跳转回小程序的页面fail')
					},
				});
			} else {
				wx.miniProgram.navigateBack({
					success: function() {
					},
					fail: function() {
					},
				});
			}
            // wx.miniProgram.getEnv(function(res) {
            //     if (res.miniprogram) {
			// 		if (this.xcxFromCode) {
			// 			wx.miniProgram.navigateTo({
			// 				url: '/pages/me/survey/index', //跳转回小程序的页面,传参id, 小程序onLoad函数接收参数即可
			// 				success: function() {
			// 				},
			// 				fail: function() {
			// 				},
			// 			});
			// 		} else {
			// 			wx.miniProgram.navigateBack({
			// 				success: function() {
			// 				},
			// 				fail: function() {
			// 				},
			// 			});
			// 		}
			// 	} else {
			// 	}
            // });
        },
		verify() {
			for (let i = 0; i < this.questions.length; i++) {
				let item = this.questions[i];
                if(item.isDel == 1) {
                    continue
                }
				// 必填
				if (item.isRequire == 1 && item.isDel != 1) {
					// 简答 填空 评分
					if (item.type == 'input' || item.type == 'textarea' || item.type == 'slider') {
						if (!item.answerContent) {
							let msg = `请输入第${i+1}题目答案`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false;
						}
					} else {
						let checkedFlag = item.subItemDtoList.some(item => {
							return item.checkedFlag
						});
						if (!checkedFlag) {
							let msg = `请输入第${i+1}题目答案`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false;
						}
					}
				}
				if (item.type == 'checkbox') {
					let count = 0;
					for (let i = 0; i < item.subItemDtoList.length; i++) {
						if (item.subItemDtoList[i].checkedFlag) ++count;
					}
					if (count < item.min) {
						let msg = `第${i+1}题至少选择${item.min}项`;
						this.$message.warning(msg);
						this.$nextTick(() => {
							window.scrollTo({
								behavior: 'smooth',
								top: this.$refs['q' + i][0].offsetTop
							})
						})
						return false;
					}
				}
				if (item.type == 'radio' || item.type == 'checkbox') {
					for (let option of item.subItemDtoList) {
						if (option.checkedFlag && !option.answerBlank && option.isRequire == 1 && option.isBlank == 1) {
							let msg = `请输入第${i+1}题必填项`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false;
						}
					}
				}
				if (item.type == 'rate') {
					let idx = -1;
					for (let o = 0; o < item.subItemDtoList.length; o++) {
						let option = item.subItemDtoList[o];
						if (option.checkedFlag) ++idx
						item.subItemDtoList[o].checkedFlag = false;
					}
					idx != -1 && (item.subItemDtoList[idx].checkedFlag = true);
				}
				// delete item.isMap;
				// delete item.isMust;
				// delete item.max;
				// delete item.min;
				// delete item.options;
				// delete item.rateStyle;
				// delete item.type;
				// delete item.val;
			}
			return true;
		},
		subTip() {
			if(this.submitRule == 1 || this.submitRule == 2) {
				// this.$confirm({
				// 	title: '提示',
				// 	content: '提交后不可修改，是否确认提交？',
				// 	okText: '确认',
				// 	okType: 'danger',
				// 	cancelText: '关闭',
				// 	onOk: () => {
				// 		this.sub()
				// 	}
				// });
				this.subShow = true;
			}  else {
				this.sub()
			}
			
		},
		sub() {
			if (!this.verify()) return;
			this.loading = true;
			let data = {
				id: this.questionId,
				questionSubDtoList: this.questions
			}
			submitQuestion(data, this.time, this.account, this.source, this.taskId, this.initiator).then(res => {
				this.loading = false
				if (res.data.type == 'success') {
					this.$message.success(res.data.text);
					if (this.source == 'WEBNEW') {
						// this.$router.go(-1);
                        this.$router.push({path: "answered"})
					}
					if (this.source == 'XCX') {
						this.submitted = true;
						this.subMsg = '请关闭当前页面以返回小程序';
                        this.xcxJump();
						return;
					}
					if (this.source == 'APP') {
						this.submitted = true;
						this.subMsg = '请关闭当前页面以返回APP';
						return;
					}
				}
				if (res.data.code == 400) {
					this.$message.warning(res.data.msg)
				}
			})
		},
		cancle() {
			// this.$confirm({
			// 	title: '提示',
			// 	content: '退出后将不会保存作答结果，是否确认退出？',
			// 	okText: '确认',
			// 	okType: 'danger',
			// 	cancelText: '关闭',
			// 	onOk: () => {
			// 		if (this.source == 'WEBNEW') {
			// 			this.$router.go(-1);
			// 		}
			// 		if (this.source == 'XCX') {
			// 			this.cancled = true;
			// 			this.subMsg = '请关闭当前页面以返回小程序';
			// 			return;
			// 		}
			// 		if (this.source == 'APP') {
			// 			this.cancled = true;
			// 			this.subMsg = '请关闭当前页面以返回APP';
			// 			return;
			// 		}
			// 	}
			// });
			this.cancleShow = true;
		},
	}
};
